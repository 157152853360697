import React from "react";

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  Tooltip,
} from "@chakra-ui/react";

import { ListNodeTypes } from "./type";

type NodeListProps = {
  isOpen: boolean;
  onClose: () => void;
  nodeTypes: ListNodeTypes[];
  handleNodeClick: (nodeType: string) => void;
};

const NewNodeList: React.FC<NodeListProps> = ({
  isOpen,
  onClose,
  nodeTypes,
  handleNodeClick,
}: NodeListProps) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Node</DrawerHeader>

        <DrawerBody>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={4}
            mt={10}
          >
            {nodeTypes.map((node) => {
              return (
                <Tooltip label={node.info} key={node.id}>
                  <Box
                    as="button"
                    key={node.id}
                    height={100}
                    border={"1px solid black"}
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={5}
                    onClick={() => handleNodeClick(node.type)}
                    disabled={node.isDisabled}
                    _disabled={{
                      opacity: 0.3,
                      cursor: "not-allowed",
                    }}
                  >
                    <Icon as={node.icon} fontSize={"2xl"} />
                    {node.name}
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NewNodeList;
