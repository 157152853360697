import React from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "@xyflow/react";

import { Box, useDisclosure, Text } from "@chakra-ui/react";

import DrawerEdgeForm from "./DrawerEdgeForm";
import { PathWayEdgeProps } from "./type";

const PathWayEdge: React.FC<PathWayEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  source,
  target,
  data,
  style = {},
}: PathWayEdgeProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <Box
          position={"absolute"}
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
          fontSize={"lg"}
          pointerEvents={"all"}
          className="nodrag nopan"
          bgColor={"white"}
          border={"1px dashed black"}
          borderRadius={5}
          p={1}
          onDoubleClick={onOpen}
        >
          <Text fontSize={"sm"}>{data.edgeCondition}</Text>
        </Box>
      </EdgeLabelRenderer>
      <DrawerEdgeForm
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        source={source}
        target={target}
        data={data}
      />
    </>
  );
};

export default PathWayEdge;
