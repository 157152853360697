import React, { useState } from "react";

import {
  VStack,
  Text,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { signUpForBeta } from "./../../api/landingPage/index";

const schema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters long"),
  email: z.string().email("Invalid email address"),
});

const ContactForm: React.FC = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await signUpForBeta({ name: data.name, email: data.email });
      toast({
        title: "Success!",
        description: "You've been added to the list. We'll be in touch soon!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear the form after successful submission
      reset();
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "An unknown error occurred",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack spacing={4} align="stretch" width={"100%"}>
      <Text
        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
        fontWeight="500"
        textAlign="center"
        mb={2}
      >
        Coming in Beta this fall
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.name} mb={4}>
          <Input
            {...register("name")}
            border={"white"}
            bgColor={"white"}
            placeholder="Name"
            size={{ base: "lg" }}
          />
          <FormErrorMessage>
            {errors.name?.message as React.ReactNode}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <Input
            {...register("email")}
            border={"white"}
            bgColor={"white"}
            type="email"
            size={{ base: "lg" }}
            placeholder="Email"
          />
          <FormErrorMessage>
            {errors.email?.message as React.ReactNode}
          </FormErrorMessage>
        </FormControl>

        <Button
          isLoading={isLoading}
          rightIcon={<ArrowForwardIcon />}
          bgColor={"black"}
          color={"white"}
          variant="solid"
          fontWeight={300}
          width={"full"}
          size={{ base: "md", md: "lg" }}
          borderRadius={"xl"}
          _hover={{
            bgColor: "black",
          }}
          type="submit"
          mt={{ base: 10, md: 10 }}
          isDisabled={isLoading || !isValid}
          _disabled={{
            bgColor: "gray.400",
            color: "white",
            cursor: "not-allowed",
          }}
        >
          Get on the list
        </Button>
      </form>
    </VStack>
  );
};

export default ContactForm;
