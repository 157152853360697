import React from "react";

import { Flex, Box, Text, useBreakpointValue } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { FeatureProps } from "./type";

const Feature: React.FC<FeatureProps> = ({
  header,
  description,
  animationData,
  reverse = false,
  component,
}) => {
  // Determine if the reverse effect should be applied based on the screen size
  const shouldReverse = useBreakpointValue({ base: false, md: reverse });

  const textSubComponent = (
    <Box
      p={{ base: 2, md: 6, lg: 6 }}
      width={{ base: "100%", md: "50%", lg: "50%" }}
      textAlign={{ base: "center", md: "left", lg: "left" }}
    >
      <Text fontSize={{ base: "2xl", md: "4xl", lg: "4xl" }} fontWeight={500}>
        {header}
      </Text>
      <Text fontSize={{ base: "lg", md: "xl", lg: "xl" }} fontWeight={300}>
        {description}
      </Text>
    </Box>
  );

  const animationSubComponent = (
    <Box
      width={{ base: "100%", md: "50%", lg: "50%" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {component ? (
        React.isValidElement(component) ? (
          component
        ) : (
          React.createElement(component)
        )
      ) : (
        <Lottie
          animationData={animationData}
          style={{ width: "75%", height: "75%" }}
        />
      )}
    </Box>
  );
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justifyContent={{ base: "center", md: "space-between" }}
      alignItems={{ base: "center", md: "center" }}
      gap={6}
      width={{ base: "95%", md: "80%", lg: "80%" }}
      margin={"auto"}
      mt={{ base: 10, md: 10, lg: 10 }}
    >
      {shouldReverse ? (
        <>
          {animationSubComponent}
          {textSubComponent}
        </>
      ) : (
        <>
          {textSubComponent}
          {animationSubComponent}
        </>
      )}
    </Flex>
  );
};

export default Feature;
