import React, { useState, useEffect } from "react";
import { Box, Container, Flex, Heading, Input, Button, VStack, Text, InputGroup, InputRightElement, IconButton, useToast } from "@chakra-ui/react";
import { ViewIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import landingPageBG from "../../assets/images/landingPageBG.jpg";
import { login } from "../../api/login";

const Login: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/dashboard");
        }
    }, [navigate]);

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const response = await login(email, password);
            localStorage.setItem("token", response.token);
            toast({
                title: "Login successful",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate("/dashboard");

        } catch (error: any) {
            toast({
                title: "Login failed",
                description: error.message || "Unable to successfully login.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            position="relative"
            width="100%"
            height="100vh"
            _before={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${landingPageBG})`,
                backgroundSize: "contain",
                backgroundRepeat: "repeat",
                opacity: 0.5,
                filter: "blur(50px)",
                zIndex: -1,
            }}
        >
            <Link to="/">
                <IconButton
                    icon={<ArrowBackIcon />}
                    aria-label="Back to homepage"
                    position="absolute"
                    top={4}
                    left={4}
                    size="lg"
                    variant="ghost"
                    zIndex={1}
                />
            </Link>
            <Flex
                width="100%"
                height="100%"
                fontFamily="Poppins"
                fontWeight={400}
                color="black"
                alignItems="center"
                justifyContent="center"
            >
                <Container maxWidth="400px">
                    <Box
                        width="100%"
                        p={8}
                        borderRadius="2xl"
                        boxShadow="xl"
                        bg="white"
                    >
                        <VStack spacing={6} align="stretch">
                            <Heading
                                as="h2"
                                size="xl"
                                fontWeight={600}
                                textAlign="left"
                                mb={2}
                            >
                                Sign in
                            </Heading>
                            <Input
                                placeholder="Email"
                                type="email"
                                size="lg"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <InputGroup size="lg">
                                <Input
                                    pr="4.5rem"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                        <ViewIcon />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Text color="blue.500" fontSize="sm" alignSelf="flex-start" cursor="pointer">
                                Forgot password?
                            </Text>
                            <Button
                                colorScheme="blackAlpha"
                                bg="black"
                                size="lg"
                                width="100%"
                                onClick={handleLogin}
                                isLoading={isLoading}
                            >
                                Login
                            </Button>
                        </VStack>
                    </Box>
                </Container>
            </Flex>
        </Box>
    );
};

export default Login;