import { Box, Button, Divider, Text, useDisclosure } from "@chakra-ui/react";
import { Handle, Position } from "@xyflow/react";

import DrawerNodeForm from "./DrawerNodeForm";
import { ProcessNodeProps, Tool } from "./type";
import { allSensitiveToolsOptions } from "./utils";
import { useEffect, useState } from "react";
import { getAvailableTools } from "../../api/agents";

const ProcessNode: React.FC<ProcessNodeProps> = ({
  id,
  data,
}: ProcessNodeProps) => {
  const [allToolsOptions, setAllToolsOptions] = useState<Tool[]>([]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await getAvailableTools();
        const toolsWithIds = response.data.map((tool: Tool, index: number) => ({
          ...tool,
          id: index,
        }));
        setAllToolsOptions(toolsWithIds);
      } catch (error) {
        console.error("Error fetching tools:", error);
      }
    };
    fetchTools();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box bgColor={"gray"} borderRadius={5} color={"white"}>
        {data.isPrimaryNode ? (
          <>
            <Text p={2}>Primary Node</Text>
            <Divider />
          </>
        ) : (
          ""
        )}

        <Box p={2}>
          <Text>{data.label}</Text>
          <Button colorScheme="green" size={"sm"} onClick={onOpen} mt={2}>
            Customise Node
          </Button>
        </Box>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </Box>
      {/* {allToolsOptions.length > 0 && ( */}
      <DrawerNodeForm
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        allToolsOptions={allToolsOptions}
        allSensitiveToolsOptions={allSensitiveToolsOptions}
      />
      {/* )} */}
    </>
  );
};

export default ProcessNode;
