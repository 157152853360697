import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <Box
      mt={{ base: 20, md: 40, lg: 40 }}
      width={"100%"}
      bgColor={"gray.800"}
      color={"white"}
      py={10}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "center" }}
        justifyContent={{ base: "center", md: "space-between" }}
        px={{ base: 10, md: 40 }}
      >
        <Box width={{ base: "100%", md: "inherit" }}>
          <Text fontSize={"xl"} fontWeight={500}>
            Agnostech AI
          </Text>
        </Box>

        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "center" }}
          justifyContent={{ base: "center", md: "space-between" }}
          width={{ base: "100%", md: "inherit" }}
          mt={{ base: 10, md: 0 }}
        >
          <Box mr={{ base: 0, md: 10 }} width={{ base: "100%", md: "inherit" }}>
            <Text fontSize={"lg"}>Email us</Text>
            <Text fontSize={"sm"} mt={{ base: 0, md: 3 }}>
              <a href="mailto:support@agnostech.ai">support@agnostech.ai</a>
            </Text>
          </Box>
          <Box mt={{ base: 3, md: 0 }} width={{ base: "100%", md: "inherit" }}>
            <Text fontSize={"lg"}>Social</Text>
            <Flex direction={"row"} gap={2} mt={{ base: 0, md: 3 }}>
              <FaInstagram
                fontSize={20}
                cursor={"pointer"}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/agnostech.ai",
                    "_blank"
                  )
                }
              />
              <FaLinkedin
                fontSize={20}
                cursor={"pointer"}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/rapid-dev",
                    "_blank"
                  )
                }
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
