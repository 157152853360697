import { api } from "../apiService";

export interface CreateUpdateAgent {
  configuration: Record<string, any>;
  updatedBy: string;
  callNumber: string;
  type: string;
}

export const getOrganisationAgents = async (organisationId: string) => {
  const response = await api.get(`/organisations/${organisationId}/agents`);
  return response.data;
};

export const getOrganisationAgent = async (
  organisationId: string,
  agentId: string
) => {
  const response = await api.get(
    `/organisations/${organisationId}/agents/${agentId}`
  );
  return response.data;
};

export const createOrganisationAgent = async (
  organisationId: string,
  data: CreateUpdateAgent
) => {
  const response = await api.post(
    `/organisations/${organisationId}/agents`,
    data
  );
  return response.data;
};

export const updateOrganisationAgent = async (
  organisationId: string,
  agentId: string,
  data: CreateUpdateAgent
) => {
  const response = await api.patch(
    `/organisations/${organisationId}/agents/${agentId}`,
    data
  );
  return response.data;
};

export const deleteOrganisationAgent = async (
  organisationId: string,
  agentId: string
) => {
  const response = await api.delete(
    `/organisations/${organisationId}/agents/${agentId}`
  );
  return response.data;
};

export const getAvailableTools = async () => {
  const response = await api.get(`/get-tools`);
  return response.data;
};
