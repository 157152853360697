import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./pages/LandingPageV2";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AgentBuilder from "./pages/AgentBuilder";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import "./fonts/the-youngest-serif-display.ttf";
import ChatPage from "./pages/Chat";
import CPWBuilder from "./pages/CPWBuilder";
import Agents from "./components/Agents";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/cpw/new",
    element: <CPWBuilder />,
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "agents",
        element: <Agents />,
      },
      {
        path: "agent/mna/new",
        element: <AgentBuilder />,
      },
      {
        path: "agent/mna/:agentId",
        element: <AgentBuilder />,
      },
      {
        path: "agent/cpw/new",
        element: <CPWBuilder />,
      },
      {
        path: "agent/cpw/:agentId",
        element: <CPWBuilder />,
      },
      {
        path: "chats",
        element: <ChatPage />,
      },
      {
        path: "agent-metrics",
        element: <div>Agent Metrics</div>,
      },
      {
        path: "contacts",
        element: <div>Contacts</div>,
      },
      {
        path: "knowledge-base",
        element: <div>Knowledge Base</div>,
      },
      {
        path: "campaigns",
        element: <div>Campaigns</div>,
      },
      {
        path: "settings",
        element: <div>Settings</div>,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
