import animationData3 from "../../assets/animations/Animation - 1725170545388.json";
import animationData5 from "../../assets/animations/Animation - 1725171901035.json";

import DemoCall from "../../components/DemoCall";

export const features = [
  {
    header: "Fast and accurate resolutions",
    description:
      "Each customer is unique, but the service they get should be the same every time – no hold times, immediate authentication, and quick answers.",
    animationData: animationData3,
    reverse: false,
  },
  {
    header: "Human-like voice capabilities",
    description:
      "Pick a voice or clone yours. Low latency (~600ms) conversation guarantees that your customers will love our voice solution.",
    animationData: undefined,
    reverse: true,
    component: DemoCall,
  },
  {
    header: "Smart Integrations into your existing workflows",
    description:
      "Connect to your CRM tool, Google Suite, or pick from one of our pre-built integrations. Don't see what you want? It's simple, use our pathway designer to build integrations.",
    animationData: animationData5,
    reverse: false,
  },
];
