import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";

import { AIType, sendChatMessage } from "../../api/chat";

interface Message {
  id: number;
  content: string;
  sender: "user" | "assistant";
}

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [aiPower, setAiPower] = useState("high");
  const [llmType, setLlmType] = useState("openai");
  const [context, setContext] = useState("You are a helpful assistant.");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      const newUserMessage: Message = {
        id: Date.now(),
        content: inputMessage,
        sender: "user",
      };
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setInputMessage("");

      try {
        const chatRequest = {
          history: messages.map((msg) => ({
            sender: msg.sender,
            content: msg.content,
          })),
          message: inputMessage,
          context: context,
          ai: llmType === "openai" ? AIType.GPT : AIType.CLAUDE,
          power: aiPower,
        };

        const response = await sendChatMessage(chatRequest);

        const botResponse: Message = {
          id: Date.now() + 1,
          content: response.response,
          sender: "assistant",
        };
        setMessages((prevMessages) => [...prevMessages, botResponse]);
      } catch (error) {
        console.error("Error sending message:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  return (
    <Flex height="100%" flexDirection="column">
      <Flex flexDirection="column" flex={1} position="relative">
        <Box position="absolute" top={4} left={4} zIndex={10}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Settings"
              icon={<ChevronDownIcon />}
              variant="outline"
              size="sm"
              _hover={{
                width: "auto",
                transition: "width 0.2s",
                "& > span": { display: "inline" },
              }}
            >
              <Text as="span" display="none" ml={2} verticalAlign="middle">
                Settings
              </Text>
            </MenuButton>
            <MenuList p={4}>
              <MenuItem _hover={{}} _focus={{}}>
                <Flex alignItems="center" width="100%">
                  <Text fontWeight="bold" width="100px">
                    AI Power
                  </Text>
                  <Flex ml={4}>
                    {["Low", "Medium", "High"].map((power) => (
                      <Button
                        key={power}
                        size="sm"
                        variant={
                          aiPower === power.toLowerCase() ? "solid" : "outline"
                        }
                        onClick={() => setAiPower(power.toLowerCase())}
                        mr={2}
                      >
                        {power}
                      </Button>
                    ))}
                  </Flex>
                </Flex>
              </MenuItem>
              <MenuItem mt={4} _hover={{}} _focus={{}}>
                <Flex alignItems="center" width="100%">
                  <Text fontWeight="bold" width="100px">
                    LLM Type
                  </Text>
                  <Flex ml={4}>
                    {["Openai", "Claude"].map((type) => (
                      <Button
                        key={type}
                        size="sm"
                        variant={
                          llmType === type.toLowerCase() ? "solid" : "outline"
                        }
                        onClick={() => setLlmType(type.toLowerCase())}
                        mr={2}
                      >
                        {type}
                      </Button>
                    ))}
                  </Flex>
                </Flex>
              </MenuItem>
              <MenuItem mt={4} _hover={{}} _focus={{}}>
                <Flex alignItems="center" width="100%">
                  <Text fontWeight="bold" width="100px">
                    Context
                  </Text>
                  <Button
                    leftIcon={<EditIcon />}
                    onClick={onOpen}
                    ml={4}
                    size="sm"
                  >
                    Edit
                  </Button>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box flex={1} overflowY="auto" p={6} pt={16}>
          <VStack spacing={4} align="stretch">
            {messages.map((message) => (
              <Flex
                key={message.id}
                justify={message.sender === "user" ? "flex-end" : "flex-start"}
              >
                <Box
                  maxW="70%"
                  bg={message.sender === "user" ? "blue.500" : "white"}
                  color={message.sender === "user" ? "white" : "black"}
                  borderRadius="lg"
                  px={4}
                  py={2}
                  boxShadow="md"
                >
                  <Text>{message.content}</Text>
                </Box>
              </Flex>
            ))}
            <div ref={messagesEndRef} />
          </VStack>
        </Box>
        <Flex p={4} bg="white" alignItems="center">
          <Input
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type a message..."
            mr={2}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <Button onClick={handleSendMessage} colorScheme="blue">
            Send
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Chat Context</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={context}
              onChange={(e) => setContext(e.target.value)}
              placeholder="Enter the context for the chat (system prompt)"
              size="sm"
              resize="vertical"
              minHeight="150px"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ChatPage;
