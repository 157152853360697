import { Tool } from "./type";

export const allToolsOptions: Tool[] = [
  {
    id: 0,
    name: "text_message_prescription_validator",
    description:
      "Use this tool for the user to start a text conversation with the user and determine if a prescription is valid",
  },
  {
    id: 1,
    name: "create_salesforce_user",
    description: "Use this tool to create a new user in Salesforce",
  },
  {
    id: 2,
    name: "get_medication_by_medication_name",
    description:
      "Use this tool to get medication information by medication name",
  },
  {
    id: 3,
    name: "fetch_user_plan_information",
    description: "Use this tool to fetch user plan information",
  },
];

export const allSensitiveToolsOptions: Tool[] = [
  {
    id: 0,
    name: "create_plan_order_entry",
    description: "Use this tool to create a new plan order entry",
  },
  {
    id: 1,
    name: "delete_user_plan",
    description: "Use this tool to delete a user plan",
  },
];
