import React, { useCallback, useState } from "react";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Textarea,
  Divider,
  Text,
} from "@chakra-ui/react";

import { useReactFlow } from "@xyflow/react";
import { DrawerEdgeFormProps } from "./type";

const DrawerEdgeForm: React.FC<DrawerEdgeFormProps> = ({
  id,
  isOpen,
  onClose,
  source,
  target,
  data,
}: DrawerEdgeFormProps) => {
  const [edgeCondition, setEdgeCondition] = useState<string>(
    data.edgeCondition
  );
  const [edgeDescription, setEdgeDescription] = useState<string>(
    data.edgeDescription
  );

  const { updateEdgeData } = useReactFlow();

  const handleEdgeUpdate = useCallback(() => {
    updateEdgeData(id, {
      edgeCondition: edgeCondition,
      edgeDescription: edgeDescription,
    });
    onClose();
  }, [id, updateEdgeData, edgeCondition, edgeDescription, onClose]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text as={"h1"}> Customise Edge</Text>
        </DrawerHeader>

        <Divider mb={8} />

        <DrawerBody>
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Edge
            </Text>
          </>
          <Divider my={4} />
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Path Condition:
            </Text>
            <Text fontSize={"xs"}>
              Describe the condition that transition to next state.
            </Text>
            <Textarea
              value={edgeCondition}
              onChange={(e) => setEdgeCondition(e.target.value)}
              placeholder="Edge condition"
              size="md"
              resize={"vertical"}
            />
          </>
          <Divider my={4} />
          <>
            <Text fontSize={"xs"}>Describe the description of the edge.</Text>
            <Textarea
              value={edgeDescription}
              onChange={(e) => setEdgeDescription(e.target.value)}
              placeholder="Edge description"
              size="md"
              resize={"vertical"}
            />
          </>
          <Divider my={4} />
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleEdgeUpdate}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerEdgeForm;
