import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import SideNav from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import { Outlet } from "react-router-dom";

const Dashboard: React.FC = () => {
  return (
    <Flex height="100vh" flexDirection="column">
      <TopNav />
      <Flex flex={1}>
        <SideNav />
        <Box p={3} height={"100%"} width={"100%"} overflowY={"auto"}>
          {/* Dashboard content will go here */}
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
