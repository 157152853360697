import React from "react";
import { Box, Flex, Heading, Text, Container } from "@chakra-ui/react";

import landingPageBG from "../../assets/images/landingPageBG.jpg";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Hero from "../../components/Hero";
import Feature from "../../components/Feature";
import ContactForm from "../../components/ContactForm";

import { features } from "./data";

const LandingPage: React.FC = () => {
  return (
    <Box
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${landingPageBG})`,
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
        opacity: 0.5,
        filter: "blur(50px)",
        zIndex: -1,
      }}
    >
      <Box width="100%" fontFamily={"Poppins"} fontWeight={400} color={"black"}>
        <Container minWidth={"95%"} margin={"auto"}>
          <NavBar />

          <Box
            maxW={{ base: "90%", md: "75%", lg: "75%" }}
            mt={{ base: 20, md: 40, lg: 40 }}
          >
            <Hero />
          </Box>

          {/* features */}
          <Container
            minWidth={"95%"}
            margin={"auto"}
            id="features"
            my={{ base: 40, md: 40, lg: 40 }}
          >
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Heading
                as="h2"
                size={{ base: "xl", md: "2xl", lg: "2xl" }}
                mb={6}
                fontWeight={500}
              >
                Our Solution
              </Heading>
              <Heading
                as="h3"
                size={{ base: "lg", md: "xl", lg: "xl" }}
                mb={6}
                fontWeight={300}
                textAlign={"center"}
              >
                Intelligent Agents that handle customer interactions like your
                best human agents
              </Heading>
            </Flex>

            {/* features */}
            {features.map((feature, index) => (
              <Feature
                key={index}
                header={feature.header}
                description={feature.description}
                animationData={feature.animationData}
                reverse={feature.reverse}
                component={feature.component}
              />
            ))}

            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "center", md: "center" }}
              gap={6}
              width={{ base: "95%", md: "90%", lg: "90%" }}
              margin={"auto"}
              mt={{ base: 20, md: 40, lg: 40 }}
            >
              <Box
                width={{ base: "100%", md: "50%", lg: "50%" }}
                padding={{ base: 2, md: 6 }}
              >
                <Text
                  fontSize={{ base: "2xl", md: "4xl", lg: "4xl" }}
                  fontWeight={300}
                  textAlign={"center"}
                >
                  Want to help us get ready for prime time? Get in touch
                </Text>
              </Box>
              <Box
                width={{ base: "100%", md: "50%", lg: "50%" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                id="contact-us"
              >
                <ContactForm />
              </Box>
            </Flex>
          </Container>
        </Container>

        {/* footer */}
        <Footer />
      </Box>
    </Box>
  );
};

export default LandingPage;
