import React from "react";
import { Flex, Text, Divider, Spacer, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineLogin } from "react-icons/md";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        as="nav"
        alignItems="center"
        py={{ base: 1, md: 2 }}
      >
        <Text as="button" fontSize={{ base: "xl", sm: "2xl", md: "2xl" }} onClick={() => navigate("/")}>
          Agnostech AI
        </Text>
        <Spacer />
        <Flex gap={4} alignItems="center">
          <Text
            fontSize={{ base: "lg", sm: "md", md: "md" }}
            fontWeight={300}
            as="button"
            onClick={() => {
              const featuresSection = document.querySelector("#contact-us");
              if (featuresSection) {
                featuresSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Contact Us
          </Text>
          <Icon
            as={MdOutlineLogin}
            boxSize={6}
            color="black"
            cursor="pointer"
            onClick={() => navigate('/login')}
          />
        </Flex>
      </Flex>
      <Divider borderColor="black" />
    </>
  );
};

export default NavBar;
