import { api } from "../apiService";

export enum AIType {
    GPT = 'gpt',
    CLAUDE = 'claude'
}

interface ChatMessage {
  sender: string;
  content: string;
}

interface ChatRequest {
  history: ChatMessage[];
  message: string;
  context: string;
  ai: AIType;
  power: string;
}

export const sendChatMessage = async (chatRequest: ChatRequest) => {
  const response = await api.post("/chat", chatRequest);
  return response.data;
};