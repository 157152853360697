import React, { useState, useEffect } from "react";

import { Box, Text, useToast } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { keyframes } from "@emotion/react";

import useRetellWebCall from "../../hooks/useRetellWebCall/useRetellWebCall";
import animationData6 from "../../assets/animations/Animation - 1725771886278.json";

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const DemoCall: React.FC = () => {
  const toast = useToast();
  const { initiateCall, stopCall, isCallActive, isLoading } =
    useRetellWebCall();

  const [infoText, setInfoText] = useState<string>("Click to start call");
  const [isHovering, setIsHovering] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleAnimationClick = async () => {
    if (isButtonDisabled) return;

    if (isCallActive) {
      stopCall();
    } else {
      setIsButtonDisabled(true);
      try {
        await initiateCall();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to initiate call. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      setInfoText("Connecting...");
    } else if (isCallActive) {
      if (isHovering) {
        setInfoText("End call");
      } else {
        setInfoText("Just Talk");
      }
    } else {
      setInfoText("Click to start call");
    }
  }, [isLoading, isCallActive, isHovering]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        as="button"
        onClick={handleAnimationClick}
        width={"70%"}
        height={"70%"}
        position="relative"
        transition="all 0.3s ease-in-out"
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        _hover={{
          transform: isButtonDisabled ? "none" : "scale(1.10)",
        }}
        opacity={isButtonDisabled ? 0.5 : 1}
        cursor={isButtonDisabled ? "not-allowed" : "pointer"}
        animation={isCallActive ? "none" : `${pulseAnimation} 2s infinite`}
      >
        <Lottie
          animationData={animationData6}
          loop={isCallActive}
          autoplay={isCallActive}
          initialSegment={isCallActive ? undefined : [0, 1]}
        />
        <Text
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontSize="medium"
          color="black"
          pointerEvents="none"
        >
          {infoText}
        </Text>
      </Box>

      <Text border={"1px solid gray"} px={4} py={1} borderRadius={"2xl"}>
        Give it a try!
      </Text>
    </Box>
  );
};

export default DemoCall;
