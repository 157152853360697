import React, { useCallback, useState, useEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  HStack,
  Input,
  useToast,
  VStack,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
import {
  ReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  BackgroundVariant,
  Panel,
  Controls,
  Node,
  Edge,
  OnConnect,
  OnNodesChange,
  OnEdgesChange,
  NodeTypes,
  EdgeTypes,
} from "@xyflow/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  MdOutlineWifiCalling3,
  MdOutlineCollectionsBookmark,
  MdOutlineCallEnd,
  MdOutlineWebhook,
} from "react-icons/md";
import { BiTransfer, BiLoaderCircle } from "react-icons/bi";

import "@xyflow/react/dist/style.css";

import DefaultNode from "../../components/PathWayNodes/DefaultNode";
import EndCallNode from "../../components/PathWayNodes/EndCallNode";
import PathWayEdge from "../../components/PathWayEdges/PathWayEdge";
import {
  getOrganisationAgent,
  updateOrganisationAgent,
  createOrganisationAgent,
} from "../../api/agents";
import { AgentConfig } from "../../components/Agents/type";
import NewNodeList from "./NewNodeList";
import { ListNodeTypes } from "./type";
import KnowledgeBaseNode from "../../components/PathWayNodes/KnowledgeBaseNode";

const availableNodeTypes: ListNodeTypes[] = [
  {
    id: 0,
    icon: MdOutlineWifiCalling3,
    type: "DefaultNode",
    name: "Default",
    isDisabled: false,
    info: "This is the default node",
  },
  {
    id: 1,
    icon: MdOutlineCollectionsBookmark,
    type: "KnowledgeBaseNode",
    name: "Knowledge Base",
    isDisabled: false,
    info: "Define knowledge base for agents",
  },
  {
    id: 2,
    icon: BiTransfer,
    type: "TransferCallNode",
    name: "Transfer Call",
    isDisabled: true,
    info: "WIP",
  },
  {
    id: 3,
    icon: MdOutlineCallEnd,
    type: "EndCallNode",
    name: "End Call",
    isDisabled: false,
    info: "Agent terminal state",
  },
  {
    id: 4,
    icon: MdOutlineWebhook,
    type: "WebhookNode",
    name: "Webhook",
    isDisabled: true,
    info: "WIP",
  },
  {
    id: 5,
    icon: BiLoaderCircle,
    type: "WaitForResponseNode",
    name: "Wait For Response",
    isDisabled: true,
    info: "WIP",
  },
];

const CPWBuilder: React.FC = () => {
  const { agentId } = useParams<{ agentId: string | undefined }>();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure(); // Existing drawer modal state
  const {
    isOpen: isGlobalPromptOpen,
    onOpen: onGlobalPromptOpen,
    onClose: onGlobalPromptClose,
  } = useDisclosure(); // New state for global prompt modal

  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [callNumber, setCallNumber] = useState("");
  const [agentName, setAgentName] = useState("");
  const [globalPrompt, setGlobalPrompt] = useState("");

  const nodeTypes: NodeTypes = useMemo(
    () => ({ DefaultNode, EndCallNode, KnowledgeBaseNode }),
    []
  );
  const edgeTypes: EdgeTypes = useMemo(() => ({ PathWayEdge }), []);

  useEffect(() => {
    if (agentId) {
      getAgentState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getAgentState = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AgentConfig = await getOrganisationAgent("1", agentId!);
      setNodes(response.configuration.nodes);
      setEdges(response.configuration.edges);
      setGlobalPrompt(response.configuration.globalPrompt);
      setCallNumber(response.callNumber);
      if (response.agentName.trim() === "") {
        setAgentName(response.agentId);
      } else {
        setAgentName(response.agentName);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  const saveAgentState = useCallback(async () => {
    if (nodes.length === 0) {
      return;
    }

    const filteredCallNumber = callNumber.length < 10 ? "NA" : callNumber;
    const data = {
      configuration: { nodes, edges, globalPrompt },
      updatedBy: "test@test.com",
      callNumber: filteredCallNumber,
      agentName: agentName,
      type: "cpw",
    };

    console.log(data);

    try {
      setIsLoading(true);
      if (agentId) {
        await updateOrganisationAgent("1", agentId, data);
      } else {
        const agent: AgentConfig = await createOrganisationAgent("1", data);
        navigate(`/dashboard/agent/cpw/${agent.agentId}`, { replace: true });
      }
      toast({
        title: "Success",
        description: "Agent state saved!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, agentName, nodes, edges, callNumber]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect: OnConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "PathWayEdge",
            data: {
              edgeCondition: "condition to next pathway",
              edgeDescription: "",
            },
            animated: true,
            style: { stroke: "blue" },
          },
          eds
        )
      ),
    []
  );

  const handleAddNode = useCallback(
    (nodeType: string) => {
      console.log(nodeType);
      const selectedNode = availableNodeTypes.filter(
        (node) => node.type === nodeType
      )[0];

      const nodeId = nanoid();
      let isPrimaryNode = false;
      if (nodes.length === 0) {
        isPrimaryNode = true;
      }

      const newNode: Node = {
        id: nodeId,
        data: {
          label: "New Node",
          prompt: "Placeholder instructions for agent to say",
          loopConditionObject: {
            loopCondition: false,
            loopConditionExample: "",
          },
          globalNodeObject: {
            globalNode: false,
            globalEdgeCondition: "",
            globalEdgeDescription: "",
          },
          isPrimaryNode: isPrimaryNode,
          allowWebSearch: false,
          advancedOptionObject: {
            modelType: "higher_intelligence_model",
            temperature: 0.2,
          },
          callInfoIntoVariableObject: {
            extractInfoIntoVariable: false,
            variables: [{ varName: "", varType: "", varDescription: "" }],
          },
          nodeInfo: {
            name: selectedNode.name,
            type: selectedNode.type,
            icon: selectedNode.icon,
          },
        },
        type: nodeType,
        position: {
          x: Math.random() * 500,
          y: Math.random() * 300,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [nodes]
  );

  const handleCallNumberInput = useCallback((inputValue: string) => {
    const parsedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    if (parsedValue.length <= 12) {
      setCallNumber(parsedValue || "");
    }
  }, []);

  return (
    <Flex height="100%" flexDirection="column">
      <Box flex={1} position="relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapToGrid={true}
        >
          <Background color="#ccc" variant={BackgroundVariant.Cross} />
          <Panel position="top-left">
            {/* <IconButton
              colorScheme="blue"
              aria-label="Go Back"
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              size={"sm"}
            /> */}
            <VStack alignItems={"flex-start"}>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={onDrawerOpen} // Open drawer
                isDisabled={isLoading}
                size={"sm"}
              >
                Add Node
              </Button>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={onGlobalPromptOpen} // Open modal for global prompt
                isDisabled={isLoading}
                size={"sm"}
              >
                Global Prompt
              </Button>
            </VStack>
          </Panel>

          <Panel position="top-right">
            <HStack>
              <VStack>
                <Input
                  size={"sm"}
                  bgColor="white"
                  variant="outline"
                  placeholder="Give your agent a name"
                  value={agentName}
                  onChange={(event) => setAgentName(event.target.value)}
                  isDisabled={isLoading}
                />
                <Input
                  size={"sm"}
                  bgColor="white"
                  variant="outline"
                  placeholder="Calling Number"
                  value={callNumber}
                  onChange={(event) =>
                    handleCallNumberInput(event.target.value)
                  }
                  isDisabled={isLoading}
                />
              </VStack>

              <Button
                size={"sm"}
                colorScheme="blue"
                onClick={saveAgentState}
                isLoading={isLoading}
              >
                Save
              </Button>
            </HStack>
          </Panel>
          <Controls />
        </ReactFlow>
      </Box>
      <NewNodeList
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        nodeTypes={availableNodeTypes}
        handleNodeClick={handleAddNode}
      />
      {/* Chakra UI Modal for Global Prompt */}
      <Modal isOpen={isGlobalPromptOpen} onClose={onGlobalPromptClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Global Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={globalPrompt} // Bind to globalPrompt state
              onChange={(e) => setGlobalPrompt(e.target.value)} // Update state on change
              placeholder="Enter your global prompt here..."
              size="sm"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={onGlobalPromptClose}
              size={"sm"}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onGlobalPromptClose} size={"sm"}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CPWBuilder;
