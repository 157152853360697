import React from "react";
import { Box, Text, Divider, HStack, Icon } from "@chakra-ui/react";
import { Handle, Position } from "@xyflow/react";

import { CiPlay1 } from "react-icons/ci";

import DrawerNodeForm from "./DrawerNodeForm";
import { NodeViewProps } from "./type";

const NodeView: React.FC<NodeViewProps> = ({
  id,
  data,
  isOpen,
  onClose,
  onOpen,
}: NodeViewProps) => {
  const icon = data.isPrimaryNode ? CiPlay1 : data.nodeInfo.icon;
  const nodeText = data.isPrimaryNode ? "Start" : data.label;
  const nodeTypeName = data.nodeInfo.name;
  return (
    <>
      <Box
        border={"1px solid gray"}
        bgColor={"white"}
        borderRadius={5}
        width={300}
        maxH={200}
        overflow={"clip"}
        onDoubleClick={onOpen} // Added onDoubleClick event
      >
        <HStack
          spacing={2}
          justifyContent="space-between"
          px={2}
          fontWeight={"500"}
        >
          <HStack spacing={0}>
            <Icon as={icon} />
            <Text p={2}>{nodeText}</Text>
          </HStack>
          <Text color={"gray"}>{nodeTypeName}</Text>
        </HStack>

        <Divider />

        <Box p={2}>
          <Text>{data.prompt}</Text>
        </Box>
        {data.nodeInfo.type !== "KnowledgeBaseNode" ? (
          <>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
          </>
        ) : (
          ""
        )}
      </Box>

      <DrawerNodeForm id={id} isOpen={isOpen} onClose={onClose} data={data} />
    </>
  );
};

export default NodeView;
