import React from "react";
import { Flex, Input, IconButton, Avatar, Text, Box } from "@chakra-ui/react";
import { FiSearch, FiBell } from "react-icons/fi";

const TopNav: React.FC = () => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={4}
      bg="white"
      boxShadow="sm"
      width="100%"
    >
      <Box>
        <Text fontSize="xl" fontWeight="bold">Agnostech AI</Text>
      </Box>
      <Flex align="center" flex={1} justifyContent="center">
        <Input placeholder="Ask AI" width="50%" maxWidth="400px" />
        <IconButton
          aria-label="Search"
          icon={<FiSearch />}
          ml={2}
        />
      </Flex>
      <Flex align="center">
        <IconButton
          aria-label="Notifications"
          icon={<FiBell />}
          mr={4}
        />
        <Avatar size="sm" />
      </Flex>
    </Flex>
  );
};

export default TopNav;