import React, { useState, useEffect, useRef } from "react";
import { Heading, Box } from "@chakra-ui/react";

interface Props {
  strings: string[];
  typingSpeed?: number; // milliseconds
  deletingSpeed?: number; // milliseconds
  size?: {
    base?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  color?: string;
  fontWeight?: number;
}

const TypingAnimation: React.FC<Props> = ({
  strings,
  typingSpeed = 100,
  deletingSpeed = 50,
  size = { base: "xl", sm: "2xl", md: "4xl", lg: "4xl" },
  color = "black",
  fontWeight = 400,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const currentString = strings[currentIndex];

    const handleType = () => {
      setCurrentText((prevText) => {
        if (isDeleting) {
          return prevText.substring(0, prevText.length - 1);
        } else {
          const nextIndex = prevText.length + 1;
          return currentString.substring(0, nextIndex);
        }
      });
    };

    const scheduleNext = () => {
      timeoutRef.current = setTimeout(
        () => {
          if (isDeleting && currentText === "") {
            setIsDeleting(false);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % strings.length);
          } else if (!isDeleting && currentText === currentString) {
            setIsDeleting(true);
            timeoutRef.current = setTimeout(scheduleNext, 2000); // Pause before deleting
            return;
          }

          handleType();
          scheduleNext();
        },
        isDeleting ? deletingSpeed : typingSpeed
      );
    };

    scheduleNext();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    currentText,
    currentIndex,
    isDeleting,
    strings,
    typingSpeed,
    deletingSpeed,
  ]);

  return (
    <Heading size={size} color={color} fontWeight={fontWeight}>
      <Box as="span">{currentText}</Box>
      <Box
        as="span"
        animation="blink 0.7s infinite"
        sx={{
          "@keyframes blink": {
            "0%": { opacity: 0 },
            "50%": { opacity: 1 },
            "100%": { opacity: 0 },
          },
        }}
      >
        |
      </Box>
    </Heading>
  );
};

export default TypingAnimation;
