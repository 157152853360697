import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Text, Button, Container, VStack } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import landingPageBG from "../../assets/images/landingPageBG.jpg";

interface NotFoundProps {
  showLoginButton?: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ showLoginButton = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  return (
    <Flex
      direction="column"
      minHeight="100vh"
      _before={{
        content: '""',
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${landingPageBG})`,
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
        opacity: 0.5,
        filter: "blur(50px)",
        zIndex: -1,
      }}
    >
      <Box width="100%" fontFamily={"Poppins"} fontWeight={400} color={"black"} flex="1">
        <Container minWidth={"95%"} margin={"auto"}>
          <NavBar />
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            py={20}
            px={6}
            flex="1"
          >
            <Heading
              display="inline-block"
              as="h2"
              size="2xl"
              bgGradient="linear(to-r, teal.400, teal.600)"
              backgroundClip="text"
            >
              {showLoginButton ? "403" : "404"}
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
              {showLoginButton ? "Access Denied" : "Page Not Found"}
            </Text>
            <Text color={'gray.500'} mb={6}>
              {showLoginButton 
                ? "You need to be logged in to access this page"
                : "The page you're looking for does not seem to exist"
              }
            </Text>

            <VStack spacing={4}>
              <Button
                as={Link}
                to="/"
                colorScheme="teal"
                bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color="white"
                variant="solid"
              >
                Go to Home
              </Button>
              {isAuthenticated && (
                <Button
                  onClick={() => navigate("/dashboard")}
                  colorScheme="teal"
                  variant="outline"
                >
                  Go to Dashboard
                </Button>
              )}
              {showLoginButton && !isAuthenticated && (
                <Button
                  as={Link}
                  to="/login"
                  colorScheme="teal"
                  variant="outline"
                >
                  Login
                </Button>
              )}
            </VStack>
          </Flex>
        </Container>
      </Box>
      <Footer />
    </Flex>
  );
};

export default NotFound;