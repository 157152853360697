import { useState, useCallback } from "react";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Textarea,
  Divider,
  Text,
  Input,
  Box,
  Switch,
  Collapse,
  Flex,
  HStack,
  NumberInput,
  Select,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { useReactFlow } from "@xyflow/react";
import { BsLightbulb } from "react-icons/bs";
import { sendChatMessage, AIType } from "../../api/chat";
import { promptImprovementPrompt } from "../../constants/prompts/prompt_improvement";

import { DrawerNodeFormProps, NodeData } from "./type";
import { DeleteIcon } from "@chakra-ui/icons";

const modelTypes = [
  {
    id: 0,
    name: "Higher Intelligence Model",
    value: "higher_intelligence_model",
  },
  { id: 1, name: "Balance Model", value: "balance_model" },
  { id: 2, name: "Higher Speed Model", value: "higher_speed_model" },
];

const varTypes = [
  {
    id: 0,
    name: "String",
    value: "string",
  },
  {
    id: 1,
    name: "Integer",
    value: "integer",
  },
  {
    id: 2,
    name: "Boolean",
    value: "boolean",
  },
];

const DrawerNodeForm = ({ id, isOpen, onClose, data }: DrawerNodeFormProps) => {
  const { updateNodeData } = useReactFlow();

  const [nodeData, setNodeData] = useState<NodeData>({ ...data });
  const [improvedPrompt, setImprovedPrompt] = useState<string>("");
  const [isImprovedPromptVisible, setIsImprovedPromptVisible] = useState(false);

  const handleImprove = async () => {
    try {
      const chatRequest = {
        history: [],
        message: nodeData.prompt,
        context: promptImprovementPrompt,
        ai: AIType.GPT,
        power: "high",
      };
      const response = await sendChatMessage(chatRequest);
      setImprovedPrompt(response.response);
      setIsImprovedPromptVisible(true);
    } catch (error) {
      console.error("Error improving prompt:", error);
    }
  };

  const handleApply = () => {
    // setPrompt(improvedPrompt);
    setNodeData({ ...nodeData, prompt: improvedPrompt });
    setIsImprovedPromptVisible(false);
  };

  const handleIgnore = () => {
    setIsImprovedPromptVisible(false);
  };

  const handleNodeUpdate = useCallback(() => {
    console.log("printing node data");
    console.log(nodeData);
    updateNodeData(id, {
      ...nodeData,
    });
    onClose();
  }, [id, updateNodeData, onClose, nodeData]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text as={"h1"}> Customise Node</Text>
        </DrawerHeader>

        <Divider mb={8} />

        <DrawerBody>
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Node name:
            </Text>
            <Input
              variant="outline"
              placeholder="Outline"
              value={nodeData.label}
              onChange={(e) =>
                setNodeData({ ...nodeData, label: e.target.value })
              }
            />
          </>
          <Divider my={4} />
          <>
            <HStack>
              <Switch
                isChecked={nodeData.staticText}
                onChange={() =>
                  setNodeData((prevState) => ({
                    ...prevState,
                    staticText: !prevState.staticText,
                  }))
                }
              />
              <Text>
                Static Text (When you want the agent to say a specific dialogue.
                Uncheck to use AI generated text)
              </Text>
            </HStack>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              {nodeData.staticText ? "Text" : "Prompt"}:
            </Text>
            <Text fontSize={"xs"}>What the tool will start with.</Text>
            <Box position="relative">
              <Textarea
                value={nodeData.prompt}
                onChange={(e) =>
                  setNodeData({ ...nodeData, prompt: e.target.value })
                }
                placeholder="Provide your prompt"
                size="md"
                resize={"vertical"}
                pr="40px"
                minHeight="150px"
              />
              <Button
                position="absolute"
                top="8px"
                right="8px"
                size="xs"
                bg="white"
                color="gray.600"
                boxShadow="md"
                _hover={{ bg: "gray.100" }}
                onClick={handleImprove}
                leftIcon={<BsLightbulb size="0.8em" />}
                iconSpacing={1}
                zIndex={2}
              >
                Improve
              </Button>
            </Box>
            <Collapse in={isImprovedPromptVisible} animateOpacity>
              <Box mt={4} position="relative">
                <Textarea
                  value={improvedPrompt}
                  onChange={(e) => setImprovedPrompt(e.target.value)}
                  placeholder="Improved prompt will appear here"
                  size="md"
                  resize={"vertical"}
                  pr="110px" // Increased right padding to accommodate buttons
                  minHeight="150px"
                />
                <Flex position="absolute" bottom="8px" right="8px" zIndex={2}>
                  <Button
                    size="xs"
                    variant="outline"
                    mr={2}
                    onClick={handleIgnore}
                    bg="white"
                  >
                    Ignore
                  </Button>
                  <Button size="xs" colorScheme="blue" onClick={handleApply}>
                    Apply
                  </Button>
                </Flex>
              </Box>
            </Collapse>
          </>
          {data.isPrimaryNode && (
            <>
              <Divider my={4} />
              <>
                <HStack justifyContent={"space-between"}>
                  <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
                    Allow Web Search:
                  </Text>
                  <Switch
                    isChecked={nodeData.allowWebSearch}
                    onChange={() =>
                      setNodeData((prevState) => ({
                        ...prevState,
                        allowWebSearch: !prevState.allowWebSearch,
                      }))
                    }
                  />
                </HStack>

                <Text>
                  This will allow the Agent to go on the web to search about
                  query.
                </Text>
              </>
            </>
          )}
          <Divider my={4} />
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Loop Condition:
            </Text>
            <Switch
              isChecked={nodeData.loopConditionObject.loopCondition}
              onChange={() =>
                setNodeData((prevState) => ({
                  ...nodeData,
                  loopConditionObject: {
                    ...nodeData.loopConditionObject,
                    loopCondition: !prevState.loopConditionObject.loopCondition,
                  },
                }))
              }
            />
          </HStack>

          <Text>
            Describe the condition for when the agent should move to the next
            node, or loop on this node. If the condition is not met, the agent
            will stay on this node until the condition is met. This allows you
            to ensure that the agent has received the information requried,
            before moving on to the next node. <br /> Example - The condition is
            achieved once you have gotten the user's name.
          </Text>

          <Input
            mt={2}
            placeholder="Loop Condition Example"
            value={nodeData.loopConditionObject.loopConditionExample}
            onChange={(e) =>
              setNodeData({
                ...nodeData,
                loopConditionObject: {
                  ...nodeData.loopConditionObject,
                  loopConditionExample: e.target.value,
                },
              })
            }
          />

          <Divider my={4} />
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Global Node:
            </Text>
            <Switch
              isChecked={nodeData.globalNodeObject.globalNode}
              onChange={() =>
                setNodeData((prev) => ({
                  ...nodeData,
                  globalNodeObject: {
                    ...nodeData.globalNodeObject,
                    globalNode: !prev.globalNodeObject.globalNode,
                  },
                }))
              }
            />
          </HStack>
          <Text>
            Toggle this if you want to make this node accessible from any other
            node. Global nodes have a hidden pathway that links all other nodes,
            to this node.
          </Text>

          <Text mt={2} fontWeight={500}>
            Global Node Pathway Label (Condition to path to this node)
          </Text>
          <Input
            placeholder="Global Edge Condition"
            value={nodeData.globalNodeObject.globalEdgeCondition}
            onChange={(e) =>
              setNodeData({
                ...nodeData,
                globalNodeObject: {
                  ...nodeData.globalNodeObject,
                  globalEdgeCondition: e.target.value,
                },
              })
            }
          />

          <Text mt={2} fontWeight={500}>
            Global Node Pathway Description (Additional Description for when to
            choose this pathway)
          </Text>
          <Input
            placeholder="Global Edge Description"
            value={nodeData.globalNodeObject.globalEdgeDescription}
            onChange={(e) =>
              setNodeData({
                ...nodeData,
                globalNodeObject: {
                  ...nodeData.globalNodeObject,
                  globalEdgeDescription: e.target.value,
                },
              })
            }
          />

          <Divider my={4} />
          <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
            Advanced Options:
          </Text>
          <Text>
            Adjust settings to optimize model performance (Temperature, Latency,
            and Model Intelligence)
          </Text>
          <Select
            placeholder="Select Option"
            value={nodeData.advancedOptionObject.modelType}
            onChange={(e) => {
              console.log(e);
              setNodeData({
                ...nodeData,
                advancedOptionObject: {
                  ...nodeData.advancedOptionObject,
                  modelType: e.target.value,
                },
              });
            }}
          >
            {modelTypes.map((model) => (
              <option key={model.id} value={model.value}>
                {model.name}
              </option>
            ))}
          </Select>

          <Text mt={2}>Temperature Between 0.0 to 1.0</Text>
          <NumberInput
            value={nodeData.advancedOptionObject.temperature}
            onChange={(value) =>
              setNodeData({
                ...nodeData,
                advancedOptionObject: {
                  ...nodeData.advancedOptionObject,
                  temperature: Number(value),
                },
              })
            }
            min={0}
            max={1}
            step={0.1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Divider my={4} />
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Extract Call Info into Variables:
            </Text>
            <Switch
              isChecked={
                nodeData.callInfoIntoVariableObject.extractInfoIntoVariable
              }
              onChange={() =>
                setNodeData((prev) => ({
                  ...nodeData,
                  callInfoIntoVariableObject: {
                    ...nodeData.callInfoIntoVariableObject,
                    extractInfoIntoVariable:
                      !prev.callInfoIntoVariableObject.extractInfoIntoVariable,
                  },
                }))
              }
            />
          </HStack>
          {/* Render variables dynamically if needed */}
          {nodeData.callInfoIntoVariableObject.variables.map(
            (variable, index) => (
              <Box key={index} mt={2}>
                <VStack width={"100%"}>
                  <HStack my={2} width={"100%"}>
                    <Input
                      placeholder="Variable Name"
                      value={variable.varName}
                      onChange={(e) => {
                        const newVariables = [
                          ...nodeData.callInfoIntoVariableObject.variables,
                        ];
                        newVariables[index].varName = e.target.value;
                        setNodeData({
                          ...nodeData,
                          callInfoIntoVariableObject: {
                            ...nodeData.callInfoIntoVariableObject,
                            variables: newVariables,
                          },
                        });
                      }}
                    />
                    <Select
                      value={variable.varType}
                      placeholder="Select Option"
                      onChange={(e) => {
                        const newVariables = [
                          ...nodeData.callInfoIntoVariableObject.variables,
                        ]; // Changed from variables to nodeData.callInfoIntoVariableObject.variables
                        newVariables[index].varType = e.target.value;
                        setNodeData({
                          ...nodeData,
                          callInfoIntoVariableObject: {
                            ...nodeData.callInfoIntoVariableObject,
                            variables: newVariables,
                          },
                        });
                      }}
                    >
                      {varTypes.map((vartype) => (
                        <option key={vartype.id} value={vartype.value}>
                          {vartype.name}
                        </option>
                      ))}
                    </Select>

                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      onClick={() => {
                        let newVariables = [
                          ...nodeData.callInfoIntoVariableObject.variables,
                        ];
                        newVariables.splice(index, 1);
                        setNodeData({
                          ...nodeData,
                          callInfoIntoVariableObject: {
                            ...nodeData.callInfoIntoVariableObject,
                            variables: newVariables,
                          },
                        });
                      }}
                    />
                  </HStack>
                  <Input
                    placeholder="Variable Description"
                    value={variable.varDescription}
                    onChange={(e) => {
                      const newVariables = [
                        ...nodeData.callInfoIntoVariableObject.variables,
                      ]; // Changed from variables to nodeData.callInfoIntoVariableObject.variables
                      newVariables[index].varDescription = e.target.value;
                      setNodeData({
                        ...nodeData,
                        callInfoIntoVariableObject: {
                          ...nodeData.callInfoIntoVariableObject,
                          variables: newVariables,
                        },
                      }); // Updated to setNodeData
                    }}
                  />
                </VStack>
              </Box>
            )
          )}
          <HStack justifyContent={"flex-end"} mt={2}>
            <Button
              colorScheme="blue"
              size={"sm"}
              onClick={() => {
                const newVariables = [
                  ...nodeData.callInfoIntoVariableObject.variables,
                ];
                newVariables.push({
                  varName: "",
                  varType: "",
                  varDescription: "",
                });
                setNodeData({
                  ...nodeData,
                  callInfoIntoVariableObject: {
                    ...nodeData.callInfoIntoVariableObject,
                    variables: newVariables,
                  },
                });
              }}
            >
              {" "}
              + Add Variable
            </Button>
          </HStack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleNodeUpdate}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerNodeForm;
