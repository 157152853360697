import React from "react";
import { Box, Heading, Button } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import TypeTextAnimationV2 from "../TypeTextAnimation/TypeTextAnimationV2";

const Hero: React.FC = () => {
  return (
    <>
      <Heading
        as="h1"
        size={{ base: "2xl", md: "3xl", lg: "3xl" }}
        mb={{ base: 4, md: 6 }}
        fontWeight={400}
      >
        We are building intelligent systems to automate customer interactions
        over
      </Heading>

      <Box>
        <TypeTextAnimationV2
          strings={["Voice", "SMS", "Email", "WhatsApp"]}
          typingSpeed={200}
          deletingSpeed={100}
          size={{ base: "2xl", md: "3xl", lg: "3xl" }}
          color={"blue"}
          fontWeight={400}
        />
      </Box>

      <Button
        mt={{ base: 10, md: 10, lg: 40 }}
        rightIcon={<ArrowForwardIcon />}
        bgColor={"black"}
        color={"white"}
        variant="solid"
        fontWeight={300}
        size={{ base: "lg", md: "xl" }}
        py={{ base: 2, md: 4 }}
        px={{ base: 4, md: 10 }}
        borderRadius={"xl"}
        _hover={{
          bgColor: "black",
        }}
        onClick={() => {
          const featuresSection = document.querySelector("#features");
          if (featuresSection) {
            featuresSection.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        Explore Solutions
      </Button>
    </>
  );
};

export default Hero;
